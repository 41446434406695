<template>
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/policyCompilation' }">
				<el-button type="primary" size="mini">返回</el-button></el-breadcrumb-item>
			<el-breadcrumb-item><el-button type="text" size="mini" style="color: #555;font-size: 14px;">政策排序</el-button></el-breadcrumb-item>
		</el-breadcrumb>
		<div class="main">
			<el-table border :data="list" style="width: 100%;">
				<el-table-column label="图片" align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img" width="40" height="40" class="head_pic" />
					</template>
				</el-table-column>
				<el-table-column label="标题" align="center" prop="title" show-overflow-tooltip></el-table-column>
				<el-table-column label="排序（数字越大排的越前)" align="center" prop="display_order"></el-table-column>
				<el-table-column label="操作" align="center" width="350">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="deleteAlumn(scope.$index, list, scope.row)">删除素材</el-button>
						<el-button type="primary" size="mini" @click="xiugai(scope.$index, list, scope.row)">修改排序</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination
					background
					@current-change="handleCurrentChange"
					:current-page="1"
					:page-count="pageCount"
					layout="total,prev,pager,next,jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			id: '',
			list: [],
			currentPage: 1,
			pageCount: Math.ceil(this.total / 10),
			total: 0,
			page: 1,
			limit: 10
		};
	},
	mounted() {
		this.id = this.$route.query.id;
		this.getData();
	},
	methods: {
		deleteAlumn(index, list, row) {
			this.$confirm('确认将该政策从专栏中删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post(this.URL.adminUrl.delete_column_data + this.id, {article_ids: row.id}).then(r =>{
					this.getData();
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() =>{

				})

			}).catch(() => {
			});
		},
		getData() {
			this.$http.get(this.URL.adminUrl.CategoryArticleType + this.id, {params: {page: this.page}}).then(res => {
				this.list = res.data.data.data;
				this.total = res.data.data.meta.total;
			});
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getData();
		},
		xiugai(index, rows, row) {
			this.$prompt('政策排序', {
			})
				.then(({ value }) => {
					if (!value){
						this.$message({
							message: '请输入政策排序值',
							type: 'warning'
						});
						return false
					}
					this.$http
						.put(this.URL.adminUrl.policySort + row.id, {
							display_order: value
						})
						.then(res => {
							rows[index].display_order = value;
							this.$message({
								type: 'success',
								message: '修改成功'
							});
						});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消修改'
					});
				});
		}
	}
};
</script>

<style scoped>
.policyCompilationTitle {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-size: 18px;
	height: 40px;
}
.navbar {
	margin-bottom: 30px;
	padding: 15px;
	border-bottom: 1px solid #ccc;
}
.pages {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
}
</style>
